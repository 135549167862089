.meeting-card-top-borders {
  @media screen and (min-width: 580px) {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}

.meeting-card-bottom-borders {
  @media screen and (min-width: 580px) {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}

.stroked-round-button-36px {
  padding: 0 !important;
  background-color: transparent !important;
  color: #0097a7 !important;
  box-shadow: none !important;
  border: 1px solid #0097a7 !important;
  border-radius: 50% !important;
  min-width: 36px !important;
  width: 36px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    display: flex;
  }
}

#meeting-accordion {
  .meeting-panel {
    border-radius: 0;
    box-shadow: none !important;
    border-bottom: 1px solid $mercury;

    @media screen and (min-width: 580px) {
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.08),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    &.mat-expanded {
      @media screen and (min-width: 580px) {
        border-radius: 6px;
      }
    }
  }
  .meeting-panel-with-edit-button > .mat-expansion-panel-header .mat-content {
    align-items: center;

    > .mat-expansion-panel-header-description {
      justify-content: flex-end;
      margin-right: 28px;
    }
  }
}

#meeting-agenda-items-list {
  .suggestion-card.cdk-drag-placeholder {
    background-color: #e7f9fb;
    display: flex;
    align-items: center;

    height: 60px;
    padding: 0 24px;

    border: 2px dashed #aaa;
    box-sizing: border-box;
    box-shadow: none !important;

    .suggestion-card-title {
      font-size: 15px;
      font-weight: 500;
    }

    mat-icon,
    mat-pseudo-checkbox {
      display: none;
    }
  }
}

.meeting-emails-section-panel {
  .mat-content {
    // To show complete email icon with bagde number for number of unread emails
    overflow: visible;
  }
}

.invite-attendees-dialog-selection-list {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
  .mat-list-text {
    margin: -5px 0;
  }
}

.ga-majority-requirement-rule-select-panel {
  min-width: 470px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.main-menu-nav-list.mat-mdc-list-base > .mat-mdc-list-item.sub-item .mat-list-text > * {
  font-weight: 300;
}
