@import './sass/theme';
@import './sass/main.scss';
@import './sass/dimensions.scss';
@import './sass/colours.scss';
@import './sass/zlevels.scss';
@import './sass/layout.scss';
@import '/node_modules/@ctrl/ngx-emoji-mart/picker.css';
body,
html {
  font-family: 'Roboto', sans-serif;
}
.mat-card-actions {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

h2 {
  font-size: 32px;
}

.marginauto {
  margin: auto;
}

.canpress {
  cursor: pointer;
}

.canpresshover:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.round {
  border-radius: 50%;
}

.quizcard {
  padding: 16px;
  .question {
    button {
      flex-shrink: 0;
    }
  }
}

.mat-expansion-panel-body {
  padding: 0 0px 0px;
}

.no-mat-expansion-panel-padding-important {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.orgbrain-style-tabs {
  .mat-tab-body-wrapper {
    padding-top: 15px;
    padding-bottom: 35px;
  }
  mat-ink-bar {
    height: 3px;
  }
  .mat-tab-label {
    color: #546e7a;
    opacity: 1;
    font-weight: 400;
    padding-top: 12px;
    &-active {
      color: $orgbrain-blue;
    }
  }
}
mat-form-field.phone-wrapper {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      transition: all 0.5s ease;
    }
    width: 100%;
    max-width: 320px;
  }
  &.mat-form-field-hide-placeholder {
    .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        margin-left: 80px;
      }
    }
  }
}

mat-form-field.email-wrapper {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      transition: all 0.5s ease;
    }
    width: 100%;
    max-width: 320px;
  }
  &.mat-form-field {
    width: 100%;
    max-width: 320px;
  }
}

.mat-expansion-panel-header {
  padding: 0 16px 0 0;
}

.maxwidthcontainer-centered {
  margin-top: calc(40vh - 100px) !important;
}

@media screen and (min-width: $smallthresh) {
  .maxwidthcontainer {
    max-width: $maxContentWidth;
    width: 100%;
    margin: 25px auto;
  }
}
.custom-emoji-class {
  width: 24px;
    height: 24px;
    margin-bottom: -6px;
}
blockquote {
  margin-left: 1rem;
  border-left: 0.2em solid #dfe2e5;
  padding-left: 0.5rem;
}
