// See also orgbrain-defaultlayout.component.ts for media matcher
$mainToolbarHeight: 56px;
$maxContentWidth: 1150px; // width of content   SEE Also LayoutSevice
$contentWidth: 90vw;
$mainToolbarHeightMobile: 56px;
$mobileWidth: 600px;
$minDeviceWidth: 360px;

// ----------------------------------- OLD $maxContentWidth: 1150px; // width of content   SEE Also LayoutSevice
$contentWidth: 90vw;

.contentwidth {
  max-width: $maxContentWidth;
  width: $contentWidth;
}

$popupwidth: 840px;

$contentWidthMobile: 100vw;

$smallthresh: 600px; // These must match the value in layout

$lessThan950: 950px;

$optionWidth: 328px;

$headerheight: 97px;

$headerheightmobile: 58px;

$containerHeightMobile: calc(
  100vh - 56px
); // Hmmm not sure why we need 2 more pixel hieght to hide under

.v10 {
  height: 20px;
}

.v20 {
  height: 20px;
}

.v30 {
  height: 30px;
}

.v50 {
  height: 50px;
}

.v100 {
  height: 100px;
}

$max-table-width: 1080px;
