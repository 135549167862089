@import './dimensions';
@import './global-layout/layout';

/*  need to do these to avoid glitches when loading */

html,
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.hidden {
  visibility: hidden;
}

.hide {
  display: none !important;

  // Equivalent to .hide-medium etc...
  &-medium {
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }

  &-tablet {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  &-mobile {
    @media screen and (max-width: 480px) {
      display: none !important;
    }
  }
}

.mat-simple-snackbar {
  overflow-wrap: anywhere;
}

.conversations-wrap-closed {
  @media (max-width: 500px) {
    display: none;
  }
}

.darkerBackdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cursor-pointer {
  cursor: pointer !important;
}

button.mat-mdc-outlined-button,
a.mat-raised-button,
button.mat-raised-button {
  min-width: 110px;
  border-radius: 1000px;
  box-shadow: none !important;
}

button.mat-flat-button,
a.mat-flat-button {
  border-radius: 1000px;
}

button.mat-button,
a.mat-button {
  border-radius: 1000px;
}

button.mat-stroked-button,
a.mat-stroked-button {
  border-radius: 1000px;
}

a.mat-stroked-button {
  border-color: #0097a7;
}

button.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #b0bec5;
}

.orgbrain-data-table.transparent-background {
  .mat-toolbar {
    background-color: transparent;
  }

  .mat-table {
    background-color: transparent;

    tr {
      background-color: transparent;
    }
  }
}

mat-dialog-container.mat-mdc-dialog-container {
  border-radius: 16px;

  .mat-mdc-dialog-surface {
    border-radius: 16px;
  }

  div.container {
    margin-top: 16px;
  }
}

.mat-form-field-infix-no-border {
  .mat-form-field-infix {
    border-top: none !important;
  }
}

.orgbrain-data-table {
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 8px 5px 8px 15px !important;
      }

      padding: 0 !important;

      mat-icon {
        font-size: 1.3rem;
      }

      .mat-form-field-infix {
        padding: 0 !important;
        font-size: 1rem;
        border-top: none !important;
      }
    }

    &.selector-styled {
      .mat-form-field-flex {
        padding: 15px 5px 8px 15px !important;
      }

      .mat-select-arrow-wrapper {
        padding-top: 10px;
      }
    }
  }

  @media (max-width: $mobileWidth) {
    tr.mat-header-row {
      height: 47px;
    }

    tr.mat-footer-row,
    tr.mat-row {
      height: 39px;
    }
  }

  .mat-toolbar {
    background-color: white;
    height: unset !important;
    min-height: 44px;
    flex-wrap: wrap;

    &:not(.no-border-top) {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .mat-icon-button {
      color: #536b73;

      &[disabled] {
        opacity: 0.3;
      }
    }
  }

  .mat-sort-header-button {
    font-size: 14px;
  }

  .mat-mdc-table {
    color: #546e7a;

    table:not(.with-border-bottom) {
      td {
        border-bottom: none !important;
      }
    }

    .mat-mdc-header-cell {
      color: #123340;
      font-size: 14px;
      padding: 5px 15px;
    }

    tr,
    mat-row {
      background-color: white;

      &.canpress {
        cursor: pointer;
        outline: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      &.hoverhighlight {
        outline: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }

    td {
      padding: 5px 15px;
      color: #546e7a;
      border: none;
      border-bottom: 1px solid #e3e3e3;

      &:not(.special-icons) {
        mat-icon {
          color: #546e7a !important;
          font-size: 22px;
        }
      }
    }

    & > mat-row {
      background-color: white;

      mat-cell {
        border: none;
        color: #546e7a;

        mat-icon {
          font-size: 22px;
        }
      }
    }
  }

  [class*='tatus'],
  [class*='edit'] {
    text-align: center;
  }

  .dropzonehighlight {
    td,
    th,
    mat-paginator {
      background-color: rgba(0, 151, 167, 0.15);
    }
  }
}

.orgbrain-data-table.special {
  [class*='tatus'],
  [class*='edit'] {
    text-align: left;
  }
}

.mat-card-content-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.editing-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: row;

  .left-pane {
    flex: 1;
  }

  .right-pane {
    flex: 1;
  }
}

.double-row,
.double-row-always {
  display: flex;
  justify-content: flex-start;

  & > * {
    max-width: 50%;
  }
}

.cdk-overlay-pane {
  max-height: 99vh;
  @media screen and (max-width: 740px) {
    max-height: 90vh;
  }
}

.pseudo-mat-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;

  &.in-focus {
    color: #0097a7;
  }
}

// Leaving it here just to remember how to do it
// .ck-editor__editable {
//   max-height: 512px;
// }

.orgbrain-ngx-editor-toolbar-bottom {
  .angular-editor {
    .angular-editor-wrapper {
      p {
        // Fixes this line was set to 0 by kolkov-editor
        margin-bottom: 12px !important;
      }
    }
  }

  .angular-editor-textarea {
    outline: none !important;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    * {
      max-width: 100%;
    }
  }

  .angular-editor-toolbar {
    background-color: transparent;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
  }
  &.blurred-toolbar {
    .angular-editor-toolbar {
      * {
        opacity: 0.9;
        transition: opacity 0.3s ease;
      }
    }
  }
}
.angular-editor-outer-border {
  border: 1px solid #64646420;
  border-radius: 8px;
}

.angular-editor-outer-border-focused {
  border: 1px solid #0097a733;
  border-radius: 8px;
}
@media screen and (max-width: $smallthresh) {
  .double-row,
  .editing-wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }

  .double-row {
    & > * {
      max-width: 100%;
    }
  }

  .cdk-overlay-pane {
    max-width: 99vw !important;
  }
}

.simple-back-button {
  border: 1px solid #526e7b !important;
  color: #526e7b !important;

  mat-icon {
    margin-top: -3px;
  }
}

.d-block {
  display: block;
}

.d-small {
  @media (min-width: 580px) {
    display: none;
  }
}

.title-font {
  font: normal normal 500 32px/34px Roboto, SemiBold;
  line-height: 40px;
  @media screen and (max-width: 579px) {
    font-size: 22px;
    line-height: 28px;
    orgbrain-info-box-open-button {
      mat-icon {
        line-height: 16px !important;
      }
    }
  }
}

.link-style-wrapper-bold-blue {
  a {
    font-weight: 500;
    color: #0097a7;
    text-decoration: none;

    &:hover {
      color: #0097a7;
      text-decoration: none;
    }

    &:active {
      color: #0097a7;
      text-decoration: none;
    }

    &:visited {
      color: #0097a7;
      text-decoration: none;
    }
  }
}

.spacer-600 {
  @media (min-width: 600px) {
    margin-right: 20px !important;
  }
}

.soobmenu-global-selector {
  & > mat-nav-list {
    padding-top: 0px;
    padding-bottom: 0px;
    & > .mat-mdc-list-item {
      padding-right: 8px;
    }
    & > .mat-mdc-list-item > .mdc-list-item__content {
      padding-left: 58px !important;
      span {
        font-size: 14px;
      }
    }
  }

  .mat-mdc-list-item {
    height: 35px;

    .mdc-list-item__content {
      font-weight: 400 !important;
    }

    padding-top: 0;
  }

  mat-divider {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

.header-style-size {
  color: #123340;
  font-weight: 500;
  font-size: 32px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  &-small {
    color: #123340;
    font-weight: 500;
    font-size: 24px;
  }
}

.dialog-min-width-664-if-possible {
  @media screen and (min-width: 664px) {
    min-width: 664px;
  }
}

.fs-13 {
  font-size: 13px;

  * {
    font-size: 13px;
  }
}

.fs-14 {
  font-size: 14px;
}

.messages-wrapper-fs-14 {
  p:not(.fs-16),
  div,
  span,
  div.body-class-1 {
    font-size: 14px !important;
  }
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.superbutton-clickable {
  border-radius: 6px !important;
  padding: 0 !important;
  color: #546e7a !important;
  font-size: 13px !important;
  line-height: 24px !important;
  min-width: 54px !important;

  .mdc-button__label {
    span {
      color: #546e7a;
    }
  }

  &.superbutton-narrow {
    padding: 0px 10px !important;
    min-width: 36px !important;
    font-weight: 400 !important;
    font-size: 10px !important;
  }

  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: unset !important;

  mat-icon {
    font-size: 22px !important;
    width: 24px !important;
    height: 24px !important;
    margin-right: 0 !important;
  }

  .mat-badge-content {
    right: 10px !important;
  }
}

.mat-badge-warn {
  .mat-badge-content {
    color: #ffffff !important;
  }
}

mat-card.mat-card {
  border-radius: 6px;
}

.mat-card,
.mat-toolbar,
.mat-cell {
  color: rgba(55, 71, 79, 0.87);
}

.text-breaker {
  word-break: break-word;

  img {
    max-width: 100%;
  }
}

.br-1 {
  border-radius: 4px;
}
.br-2 {
  border-radius: 8px;
}
.br-3 {
  border-radius: 12px;
}
.br-4 {
  border-radius: 16px;
}

.m-w-100 {
  max-width: 100% !important;
}
.m-w-50 {
  max-width: 50% !important;
}
.m-w-33 {
  max-width: 33% !important;
}
.m-w-25 {
  max-width: 25% !important;
}
@media screen and (max-width: 768px) {
  .m-w-100-sm {
    max-width: 100% !important;
  }

  .m-w-50-sm {
    max-width: 50% !important;
  }

  .m-w-33-sm {
    max-width: 33% !important;
  }

  .m-w-25-sm {
    max-width: 25% !important;
  }
}
.max-width-100 {
  max-width: 100vw;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}

.w-66 {
  width: 66.666666% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}
.w-45 {
  width: 45% !important;
}
.w-44 {
  width: 44% !important;
}

.w-40 {
  width: 40% !important;
}

.w-33 {
  width: 33.333333% !important;
}
.w-30 {
  width: 30% !important;
}
.w-25 {
  width: 25% !important;
}
.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.text-center {
  text-align: center;
}

.italic-text {
  font-style: italic;
}

.max-width-738 {
  max-width: 738px;
}

.rotate90deg {
  transform: rotate(90deg) !important;
}

.flip {
  transform: rotate(180deg) !important;
}

.underline {
  text-decoration: underline;
}

.universal-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.with-label-ellipsis {
  label {
    max-width: 100%;
  }

  .mat-checkbox-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.radius-top-6px {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.radius-bottom-6px {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.standard-left-right-padding {
  padding-left: 24px;
  padding-right: 24px;
}

.light-blue-field-background {
  .mat-mdc-form-field-flex {
    align-items: flex-start !important;
    padding: 5px 5px 0 10px;
    background: rgba(178, 235, 242, 0.2);
    border-radius: 4px 4px 0px 0px;
  }
}

.light-gray-field-background {
  .mat-mdc-form-field-flex {
    align-items: flex-start !important;
    padding: 5px 5px 0 10px;
    background: rgba(185, 186, 186, 0.2);
    border-radius: 4px 4px 0px 0px;
  }
}

.mat-chip-column-mode {
  .mat-chip-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    input.mat-chip-input {
      flex: 1;
    }
  }
}

.no-mat-form-field-underline {
  .mat-form-field-underline {
    display: none !important;
  }
}

.mat-paginator-container-no-padding {
  .mat-paginator-container {
    padding: 0 !important;
  }
}

.mat-list-item-content-no-padding {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

.mat-checkbox-full-width {
  display: flex !important;
  .mdc-list-item__primary-text {
    width: 100%;
  }
  .mdc-form-field {
    align-items: stretch;
    width: 100%;
  }
  mat-checkbox {
    display: flex !important;
    width: 100% !important;
    height: 100%;
  }

  .mat-tooltip-trigger {
    width: 100% !important;
  }

  .mat-option-text {
    display: flex !important;
    height: 100%;
  }

  label {
    align-items: center;
    display: flex !important;
    flex: 1;
    justify-content: flex-start;
  }

  .mat-checkbox-inner-container {
    margin-left: 0;
  }
}

.border-box {
  box-sizing: border-box;
}

.h-100 {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-auto-mobile {
  @media screen and (max-width: 768px) {
    overflow: auto !important;
  }
}

.overflow-auto-hidden {
  overflow: auto hidden !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.orgbrain-color {
  color: #0097a7;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static !important;
}

.country-selector {
  opacity: 1 !important;
  margin-left: -10px !important;
}

.opacity70 {
  opacity: 0.7;
}

.opacity50ForInsideElements {
  & > *:not(.never-opaque) {
    opacity: 0.5;
  }
}

$sizes: -10, -5, 0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100;

@each $size in $sizes {
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }

  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }

  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }

  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }

  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }

  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
}
.select {
  background-color: transparent;
}
.select:hover {
  background-color: #eef1f3 !important;
}

.mw-100 {
  max-width: 100% !important;
}
.mw-90 {
  max-width: 90% !important;
}
.mw-50 {
  max-width: 50% !important;
}
.min-w-0 {
  min-width: 0 !important;
}
.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 500;
}

.unbold {
  font-weight: 400;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-33 {
  flex-basis: 33.33%;
}

.flex-basis-48 {
  flex-basis: 48%;
}

.flex-basis-full {
  flex-basis: 100%;
}

.row-gap-1 {
  row-gap: 1rem;
}

.column-gap-1 {
  column-gap: 1rem;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-self-end {
  justify-self: flex-end !important;
}

.justify-self-start {
  justify-self: flex-start !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

@media screen and (max-width: 768px) {
  .flex-direction-column-mobile {
    flex-direction: column !important;
  }
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-direction-big-row-599-column {
  flex-direction: row !important;

  @media screen and (max-width: 599px) {
    flex-direction: column !important;
  }
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap-mobile {
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.close-button {
  // background: #0097a7 !important;
  // color: white !important;
  // transform: scale(0.6);
  color: #123340 !important;
}

.new-add-button[class] {
  &:not(.mat-button-disabled) {
    border-color: #0097a7;

    mat-icon {
      color: #0097a7 !important;
    }
  }

  mat-icon {
    padding-right: 5px;
  }

  padding: 2px 22px 2px 15px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.mat-snack-bar-container {
  max-width: 100% !important;
  width: 460px !important;
}

.radio-black {
  .mat-radio-inner-circle {
    background-color: rgba(0, 0, 0, 0.54) !important;
  }

  .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.54) !important;
  }
}

.border-none {
  border: none !important;
}

.orgbrain-borderless-expansion-panel {
  box-shadow: none !important;
  border: none !important;

  mat-expansion-panel-header > span {
    color: #546e7a;
    font-size: 18px;
  }
}

.tabs-unbold-highlighted {
  .mdc-tab {
    min-width: 160px;
  }

  mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-tab-label {
    font-weight: 400;
  }

  .mat-tab-label-active {
    opacity: 1;

    .mat-tab-label-content {
      color: #0097a7;
    }
  }
}

.tabs-bold-highlighted {
  .mat-tab-label-active {
    font-weight: bold;
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-flex {
    align-items: flex-end;
  }
}

.form-field-without-underline {
  .mdc-line-ripple {
    &::before {
      border-bottom: none;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-form-field-border-100 {
  position: relative;

  .mat-form-field-underline {
    display: none;
  }

  &::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

.mat-form-field-border-101 {
  position: relative;
  display: inline-flex !important;
  .mat-form-field-underline {
    display: none;
  }

  &::before {
    position: absolute;
    top: 0px;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  &::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

.attachment-icon-wrapper {
  position: relative;
  max-width: 50px;
  margin-top: 2px;
  margin-right: 5px;

  .attachment-icon {
    transform: rotate(180deg);
    height: 24px;
    width: 24px;
    margin-right: 1px;
  }

  .attachment-icon-small {
    transform: rotate(180deg);
    height: 20px;
    width: 20px;
    margin-right: 1px;
    mat-icon {
      font-size: 20px;
    }
  }

  .attachment-text {
    font-size: 12px;
  }
}

.global-css-sort-cell-center {
  .mat-sort-header-container {
    padding-left: 18px;
    justify-content: center;
  }
}

.mat-expansion-indicator::after {
  margin-top: -5px;
}

.country-search {
  box-sizing: border-box;
}

.mat-autocomplete-panel {
  margin-top: 14px;
}

.standard-title-bar {
  box-sizing: border-box;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 32px !important;

  @media screen and (max-width: 760px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

//Standard font made by viktor :)
//ranked by visual importance
.orgbrain-bold-sub-header {
  color: #123340;
  font-weight: 500;
  font-size: 22px;
}
.orgbrain-sub-header {
  color: #123340;
  font-weight: 400;
  font-size: 22px;
}
.orgbrain-bold-larger-text {
  color: #123340;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.orgbrain-larger-text {
  color: #123340;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.orgbrain-larger-gray-text {
  color: #546e7a;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.orgbrain-bold-text {
  color: #123340;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.orgbrain-text {
  color: #123340;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.orgbrain-bold-gray-text {
  color: #546e7a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.orgbrain-gray-text {
  color: #546e7a;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.orgbrain-small-bold-text {
  color: #123340;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.orgbrain-small-bold-gray-text {
  color: #546e7aba;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.orgbrain-small-gray-text {
  color: #546e7a;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.orgbrain-smallest-gray-text {
  color: #546e7a;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}
// .orgbrain-small-error-text {
//   color: red;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 18px;
// }
.orgbrain-default-font {
  font-family: Roboto, sans-serif;
}
.wide-tooltip .mdc-tooltip__surface {
  max-width: 400px;
}
//customize tooltips
.mat-mdc-tooltip-panel {
  display: flex;
  justify-content: center;
}
// Tailwind inspired helpers, 1 is 4px, 4 is 16px or 1 rem, 8 is 32px etc.

// r,l,t,b stands for right, left, top, bottom respectively
// All need to be suffixed with an x to not interfere with Bogdans styling
// x adjusts the horizontal spacing as in a shorthand for r,l
// y adjusts the vertical spacing as in a shorthand for top, bottom
// p is padding
// m is margin

// Helper functions for flexbox:
// d-flex exists already above
// justify-content: space-between is jc-sb
// justify-content: start is jc-s etc.

// Mobile friendly helpers:
// When a styling should only be applied to a screen under 768px (i.e. mobile)
// it can be suffixed with sm (small)

// Using this, is kind of like inline styling

$top-boundary: 100; // 24 different sizes from 4px to 96px. Increase this, but never decrease it in case it is used!
$interval: 4; // 4 px intervals
$props: (
  ptx: (
    padding-top,
  ),
  pbx: (
    padding-bottom,
  ),
  plx: (
    padding-left,
  ),
  prx: (
    padding-right,
  ),
  mtx: (
    margin-top,
  ),
  mbx: (
    margin-bottom,
  ),
  mlx: (
    margin-left,
  ),
  mrx: (
    margin-right,
  ),
  mx: (
    margin-right,
    margin-left,
  ),
  my: (
    margin-top,
    margin-bottom,
  ),
  px: (
    padding-right,
    padding-left,
  ),
  py: (
    padding-top,
    padding-bottom,
  ),
  gap-y: (
    row-gap,
  ),
  gap-x: (
    column-gap,
  ),
  gap: (
    gap,
  ),
  hx: (
    height,
  ),
  wx: (
    width,
  ),
);

@each $key, $value in $props {
  @for $i from 0 through $top-boundary {
    .#{$key}-#{$i} {
      @each $prop in $value {
        #{$prop}: #{($i * $interval)}px !important;
      }
    }
  }
}

.fb-quarter {
  flex-basis: 25% !important;
}

.fb-half {
  flex-basis: 48% !important;
}

.fb-three-quarters {
  flex-basis: 75% !important;
}

.fb-full {
  flex-basis: 100% !important;
}

.basis-one-third {
  flex-basis: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.justify-self-center {
  justify-self: center !important;
}

.justify-self-end {
  justify-self: end !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

$text-align-suffixes: (left, right, center, justify);

@each $suffix in $text-align-suffixes {
  .text-#{$suffix} {
    text-align: #{$suffix} !important;
  }
}

$flex-props: (
  justify: justify-content,
  items: align-items,
);

$flex-suffixes: (
  between: space-between,
  evenly: space-evenly,
  start: flex-start,
  center: center,
  end: flex-end,
  baseline: baseline,
);

@each $propkey, $prop in $flex-props {
  @each $suffixkey, $suffix in $flex-suffixes {
    .#{$propkey}-#{$suffixkey} {
      #{$prop}: #{$suffix} !important;
    }
  }
}

.d-grid {
  display: grid !important;
}

$maxGridColumns: 12;

@for $i from 1 through $maxGridColumns {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }

  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }

  .col-start-#{$i} {
    grid-column-start: #{$i};
  }

  .col-end-#{$i} {
    grid-column-end: #{$i};
  }

  .col-start-#{$i} {
    grid-column-start: #{$i};
  }

  .row-span-#{$i} {
    grid-row: span #{$i} / span #{$i};
  }

  .row-start-#{$i} {
    grid-row-start: #{$i};
  }

  .row-end-#{$i} {
    grid-row-end: #{$i};
  }
}

$font-props: (
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
);

@each $key, $value in $font-props {
  .font-#{$key} {
    font-weight: #{$value} !important;
  }
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.d-none {
  display: none !important;
}

@media screen and (max-width: 1278px) {
  .flex-col-1278 {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1322px) {
  .d-block-1322 {
    display: block !important;
  }

  .w-100-1322 {
    width: 100% !important;
  }

  .w-50-1322 {
    width: 50% !important;
  }

  .flex-1-1322 {
    flex: 1 !important;
  }

  .flex-2-1322 {
    flex: 2 !important;
  }
}

@media screen and (max-width: 768px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }

  .flex-nowrap-sm {
    flex-wrap: nowrap;
  }

  .row-reverse-sm {
    flex-direction: row-reverse !important;
  }

  .d-flex-sm {
    display: flex !important;
  }

  .d-none-sm {
    display: none !important;
  }

  .d-block-sm {
    display: block !important;
  }

  .justify-content-between-sm {
    justify-content: space-between !important;
  }

  .text-sm-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .text-lg-sm {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .text-base-sm {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  @each $key, $value in $props {
    @for $i from 0 through $top-boundary {
      .#{$key}-#{$i}-sm {
        @each $prop in $value {
          #{$prop}: #{($i * $interval)}px !important;
        }
      }
    }
  }

  @for $i from 1 through $maxGridColumns {
    .grid-cols-#{$i}-sm {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }

    .col-span-#{$i}-sm {
      grid-column: span #{$i} / span #{$i};
    }

    .col-start-#{$i}-sm {
      grid-column-start: #{$i};
    }

    .col-end-#{$i}-sm {
      grid-column-end: #{$i};
    }

    .col-start-#{$i}-sm {
      grid-column-start: #{$i};
    }
  }

  .flex-col-sm {
    flex-direction: column !important;
  }

  .fb-half-sm {
    flex-basis: 48% !important;
  }

  .fb-full-sm {
    flex-basis: 100% !important;
  }

  .flex-1-sm {
    flex: 1 !important;
  }

  .flex-2-sm {
    flex: 2 !important;
  }

  .w-100-sm {
    width: 100% !important;
  }

  .w-80-sm {
    width: 80% !important;
  }

  .w-66-sm {
    width: 66% !important;
  }

  .w-50-sm {
    width: 50% !important;
  }

  .nogap-sm {
    column-gap: 0 !important;
  }

  .w-33-sm {
    width: 33% !important;
  }

  .text-left-sm {
    text-align: left !important;
  }

  @each $propkey, $prop in $flex-props {
    @each $suffixkey, $suffix in $flex-suffixes {
      .#{$propkey}-#{$suffixkey}-sm {
        #{$prop}: #{$suffix} !important;
      }
    }
  }
}

.orgbrain-light-theme-panel {
  background: #eef1f3;
  box-sizing: border-box;
  width: 240px;

  .menuitem,
  .menuitem-static {
    color: #444444 !important;
  }

  mat-divider {
    margin-right: 15px;
    margin-left: 15px;
  }

  &.allow-disappearing-scrollbar {
    .mat-drawer-inner-container {
      overflow: hidden;

      &:hover {
        overflow-y: auto;
      }
    }
  }
}

.orgbrain-box-shadow-2 {
  box-shadow: 0px 2px 4px #00000029 !important;
}

.orgbrain-box-shadow-3 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.flex-container-shareholders > *,
thousand-separator-input > * {
  flex-grow: 1;
  margin-right: 0;
}

.mat-list-item.flex-container-shareholders {
  .mat-form-field {
    margin-right: 0;
  }
}

.flex-container-shareholders > .fill-form {
  flex-grow: 1;
}

.widest-input-possible {
  display: flex;
  width: 100%;
  padding-right: 10px;

  .mat-form-field {
    display: flex;
    width: 100%;
  }

  .mat-form-field-wrapper {
    display: flex;
    width: 100%;
  }
}

.orgbrain-info-box-box {
  background-color: #eef1f3;
}

.dialog-title-comp {
  font-size: 1.5rem;
  color: #546e7a;
}

.mb0-mat-mdc-text-field-wrapper {
  .mat-mdc-text-field-wrapper {
    margin-bottom: 0 !important;
  }
}

.pdf-styles-emulation {
  .size24px > h1 {
    font-size: 24px;
    margin-top: 0;
  }

  h1 {
    font-weight: 700;
  }

  h1.mb-0 {
    font-size: 18px;
    margin-top: 8px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.allow-mentioning {
  .mat-mdc-text-field-wrapper {
    overflow: visible;
  }
}

// [mention] tag controls it
mention-list {
  left: 0px !important;

  ul.dropdown-menu {
    max-width: 430px;

    @media screen and (max-width: 449px) {
      max-width: calc(100vw - 30px);
    }

    padding: 0 !important;

    li {
      a {
        padding-top: 12px !important;
        padding-bottom: 12px !important;

        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }

      padding: 0;
      cursor: pointer;

      &:hover {
        a {
          background-color: rgb(232, 232, 235);
        }
      }

      &.active {
        a {
          background-color: #0097a7 !important;
        }
      }
    }
  }
}

.fix-the-badge {
  .mat-badge-content {
    font-size: 9px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    width: 17px !important;
    height: 17px !important;
  }
}

.fix-the-badge-2 {
  .mat-badge-content {
    font-size: 9px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    width: 17px !important;
    height: 17px !important;
  }
}

.form-suffix-fix {
  .mat-form-field-suffix {
    align-items: center;
    display: flex;
  }
}

.child-has-no-padding {
  & > * {
    padding: 0 !important;
  }
}

.number-align {
  text-align: right !important;
  justify-content: flex-end !important;
}

.ta-left {
  text-align: left !important;

  & * {
    text-align: left !important;
  }
}

.radio-after-label {
  .mat-radio-label {
    display: flex;
    flex-direction: row-reverse;
  }

  .mat-radio-label-content {
    padding-right: 5px;
    padding-left: 0;
  }
}

.mat-menu-item-checkbox {
  label {
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .mat-checkbox-inner-container {
    margin-left: 0;
  }
}

.pl-td-first {
  padding-left: 64px !important;

  @media screen and (max-width: 760px) {
    padding-left: 20px !important;
  }
}
@media screen and (min-width: 761px) {
  .w-50-from-761 {
    width: 50%;
  }
}

.orgbrain-data-table .mat-mdc-table .mat-mdc-header-cell.pl-td-first {
  padding-left: 64px !important;

  @media screen and (max-width: 760px) {
    padding-left: 16px !important;
  }
}

.orgbrain-data-table .mat-mdc-table .mat-mdc-cell.pl-td-first {
  padding-left: 64px !important;

  @media screen and (max-width: 760px) {
    padding-left: 16px !important;
  }
}

.pr-td-last {
  padding-right: 64px !important;

  @media screen and (max-width: 760px) {
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 760px) {
  .pr-td-last-sm {
    padding-right: 20px !important;
  }
}

.orgbrain-data-table .mat-mdc-table .mat-mdc-header-cell.pr-td-last {
  padding-right: 48px !important;

  @media screen and (max-width: 760px) {
    padding-right: 20px !important;
  }
}

.orgbrain-data-table .mat-mdc-table .mat-mdc-cell.pr-td-last {
  padding-right: 48px !important;

  @media screen and (max-width: 760px) {
    padding-right: 20px !important;
  }
}

.shareholders-card {
  margin-top: 48px;
  margin-bottom: 80px;
  width: 100%;

  @media screen and (max-width: 760px) {
    margin-top: 0px !important;
  }
}

.sgm {
  // shareholders general margin
  margin-left: 64px;
  margin-right: 64px;

  @media screen and (max-width: 760px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.orgbrain-page-card {
  margin-top: 48px;
  margin-bottom: 80px;
  width: 100%;

  @media screen and (max-width: 760px) {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}

.orgbrain-general-margin {
  // shareholders general margin
  margin-left: 64px;
  margin-right: 64px;

  @media screen and (max-width: 760px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.sgp {
  // shareholders general padding
  padding-left: 64px;
  padding-right: 64px;

  @media screen and (max-width: 760px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.card-padding-left-64 {
  padding-left: 64px !important;

  @media screen and (max-width: 700px) {
    padding-left: 16px !important;
  }
}

.card-padding-left-48 {
  padding-left: 48px !important;

  @media screen and (max-width: 700px) {
    padding-left: 16px !important;
  }
}

.card-padding-right-48 {
  padding-right: 48px !important;

  @media screen and (max-width: 700px) {
    padding-right: 16px !important;
  }
}

.card-padding-left-22 {
  padding-left: 22px !important;

  @media screen and (max-width: 700px) {
    padding-left: 0px !important;
  }
}

.card-padding-right-22 {
  padding-right: 22px !important;

  @media screen and (max-width: 700px) {
    padding-right: 0px !important;
  }
}

.card-padding-right-54 {
  padding-right: 54px !important;

  @media screen and (max-width: 700px) {
    padding-right: 10px !important;
  }
}

.card-padding-right-47 {
  padding-right: 47px !important;

  @media screen and (max-width: 700px) {
    padding-right: 0px !important;
  }
}

.card-padding-right-64 {
  padding-right: 64px !important;

  @media screen and (max-width: 700px) {
    padding-right: 16px !important;
  }
}

.card-padding-top {
  padding-top: 42px !important;

  @media screen and (max-width: 700px) {
    padding-top: 12px !important;
  }
}

.card-padding-bottom {
  padding-bottom: 48px !important;

  @media screen and (max-width: 700px) {
    padding-bottom: 16px !important;
  }
}

.card-padding {
  padding: 48px 64px 48px 64px !important;

  @media screen and (max-width: 700px) {
    padding: 32px 16px 32px 16px !important;
  }
}

.card-padding-elegant {
  box-sizing: border-box;
  padding: 32px 48px !important;

  @media screen and (max-width: 959px) {
    padding: 10px 20px !important;
  }
}

.mat-expansion-panel-header-title,
.mat-select-value,
.mat-option {
  color: #546e7a;
}

.mat-list-base .mat-list-item {
  color: #546e7a;
}

.body-text-1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #546e7a;
}

.body-text-2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #546e7a;
}

.sub-heading {
  font-size: 1rem;
  font-weight: 500;
  font-family: Roboto;
  color: #123340;
  line-height: 1.25rem;
}

.sub-heading-3 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #123340;
  line-height: 1.125rem;
}

.mat-expansion-panel {
  background: white;
  color: rgba(55, 71, 79, 0.87);
}

.padding-standard-front-page {
  padding: 28px 32px !important;

  @media screen and (max-width: 959px) {
    padding: 10px 20px !important;
  }
}

mat-expansion-panel-header.mat-expansion-panel-header {
  @media screen and (max-width: 700px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.mat-drawer-container,
.mat-dialog-container .mat-mdc-dialog-surface {
  color: rgba(55, 71, 79, 0.87);
}

.title-2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #123340;
  @media screen and (max-width: 579px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.disabled-hack,
.disabled-hack * {
  pointer-events: none !important;
}

.title-3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #123340;
}

.selected-menu-item {
  background-color: rgba(0, 0, 0, 0.08);
}

.w-100-radio-checkbox-nesting {
  .mat-radio-label-content {
    width: 100%;
  }

  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      width: 100%;
    }
  }
}

.vis-item {
  border-radius: 40px !important;
  border-color: transparent !important;

  .vis-item-overflow {
    border-radius: 40px !important;
  }
}

.vis-time-axis .grid.vis-odd {
  background: #f5f5f5;
}

/* gray background in weekends, white text color */
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: rgba(0, 0, 0, 0.08);
}

// .vis-time-axis .vis-text.vis-saturday,
// .vis-time-axis .vis-text.vis-sunday {
//   color: white;
// }

.vis-grid.vis-saturday,
.vis-grid.vis-sunday {
  height: 100% !important;
}

.vis-delete::after {
  line-height: 25px;
}

.vis-item div.vis-item-content {
  display: flex !important;
  padding: 0;
  width: 100%;

  .vis-item-content-inner-wrapper {
    width: 100%;

    .vis-inner-item {
      background: #cbcbcb;
      padding: 5px;
    }
  }
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-2xl {
  border-radius: 1rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

div#hubspot-messages-iframe-container.hide {
  display: none !important;
}

.empty-module-text {
  font-size: 1rem;
  color: grey;
}

.orgbrain-circle-progress-spinner {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.orgbrain-circle-progress-spinner-middle-circle {
  position: absolute !important;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}

.orange-slider {
  .mat-slider-thumb-label-text {
    color: white !important;
  }

  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: #f57c00 !important;
  }

  // .mat-slider-ticks {
  //   background: #f57c00 !important;
  // }
}

.orgbrain-search-and-filter {
  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 8px 5px 8px 15px !important;
      }

      padding: 0 !important;

      mat-icon {
        font-size: 1.3rem;
      }

      .mat-form-field-infix {
        padding: 0 !important;
        font-size: 1rem;
        border-top: none !important;
      }
    }

    &.selector-styled {
      .mat-form-field-flex {
        padding: 15px 5px 8px 15px !important;
      }

      .mat-select-arrow-wrapper {
        padding-top: 10px;
      }
    }
  }
}

.orgbrain-footer-row {
  background-color: #eef1f3 !important;
  color: #546e7a;
  font-weight: 700;
}

.creation-button {
  border-color: #0097a7;
  display: flex;
  align-items: center;
}

.mat-dialog-actions {
  min-height: 36px !important;
}

.empty-description-field:hover {
  cursor: pointer;
  background-color: #eef1f3;
  border-radius: 8px;
}

.clickable:hover {
  cursor: pointer;
  background-color: #e4e7e9;
}

.menu-button {
  cursor: pointer;
}

.menu-button:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.dialog-field-title {
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: #123340;
}

.dialog-field-content {
  font-size: 0.875rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #546e7a;
}

.task-borderline {
  border-right: 1px solid #b0bec5;

  @media screen and (max-width: 768px) {
    border-right: none;
  }
}

.task-top-borderline {
  border-top: 1px solid #b0bec5;
}

.related-task-link {
  color: #0097a7;
  width: auto;
}

.related-task-link:hover {
  cursor: pointer;
  color: #0097a7;
}

.no-dialog-sides-and-bottom-padding {
  mat-dialog-container .mat-mdc-dialog-surface {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.padding-sides-32 {
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
}

.small-checkbox-text {
  font-size: 0.813rem;
}

iframe[data-test-id='interactive-frame'] {
  display: none !important;
}

.se-fasit-fix {
  .mat-mdc-list-item-unscoped-content {
    display: flex;
    align-items: center;
    text-wrap: wrap;

    mat-icon {
      box-sizing: border-box;
      min-width: 30px;
      padding-right: 10px;
    }
  }
}

.list-with-space-between {
  .mat-mdc-list-item-unscoped-content {
    display: flex;
    justify-content: space-between;
  }
}

.list-with-align-center {
  .mat-mdc-list-item-unscoped-content {
    display: flex;
    align-items: center;
  }
}

.quiz-bugfixed-radio-button {
  mat-radio-button {
    .mdc-form-field {
      display: flex;
      flex-grow: 1;

      label {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }
    }
  }
}

.bugfix-checkbox-width-100 {
  .mdc-form-field {
    width: 100%;

    label {
      width: 100%;
      display: flex;
      flex-grow: 1;
    }
  }
}

.icon-press:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  outline: 8px solid rgba(0, 0, 0, 0.1);
}

orgbrain-template-fill-in-input {
  vertical-align: middle;

  mat-form-field {
    vertical-align: middle;
  }
}

.chat-window-formfield {
  height: 100%;
  width: 100%;
  padding: 10px 16px 0 16px;

  .mdc-line-ripple {
    display: none !important;
  }

  .chat-window-textarea-message-input {
    color: #050505;
    font-size: 16px !important;

    &::placeholder {
      font-size: 16px;
    }
  }

  .mat-mdc-form-field-infix {
    padding-top: 5px !important;
    padding-bottom: 6px !important;
  }

  div.mat-mdc-text-field-wrapper.mdc-text-field--filled {
    margin-bottom: 8px !important;
  }
}

.checkbox-space-killer {
  margin-top: -10px;
  margin-left: -10px;
}

.orgbrain-settings-page {
  // This is for Organization Settings page
  orgbrain-info-box[class] {
    // Whatever crap one have put into classes, this hero css will overrdie everything to make design look good!
    // please avoid adding mbx-4 or other crap like this to orgbrain-info-box
    margin-bottom: 0 !important;

    & > div {
      margin-bottom: 16px;
    }
  }
}

.hidable-below-640 {
  @media (max-width: 640px) {
    display: none !important;
  }
}

.cursor-default {
  cursor: default !important;
}

orgbrain-tasks-table th.mat-column-priority > .mat-sort-header-container {
  justify-content: center;
}

orgbrain-tasks-table th.mat-column-status > .mat-sort-header-container {
  justify-content: center;
}

orgbrain-tasks-table .mat-column-status {
  max-width: 120px;
}

orgbrain-tasks-table .mat-column-priority {
  max-width: 120px;
}

orgbrain-tasks-table .mat-column-responsible {
  max-width: 300px;
  // Text needs to break
  word-break: break-all;
}

.orgbrain-data-table .mat-mdc-table td:not(.special-icons) mat-icon.check-complete {
  color: #0097a7 !important;
}

.make-inputs-more-visible[class] {
  .mdc-text-field--disabled .mdc-text-field__input {
    color: #546e7a;
  }
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.68);
}

.fixed-width-61px {
  width: 61px;
}

.due-diligence-padding {
  padding-left: 24px;
  padding-right: 24px;
}

.wrapper-of-navigation {
  padding-left: 16px;
  padding-right: 16px;
  .blue-text-important {
    padding-left: 0;
    padding-right: 0;
    min-width: 12px !important;
  }
}

.dataroom-add-padding {
  @media (min-width: 701px) {
    display: flex;

    .mat-mdc-table {
      tr {
        th:first-of-type {
          padding-left: 48px !important;

          mat-checkbox {
            margin-left: -10px;
          }
        }

        td:first-of-type {
          padding-left: 48px !important;

          mat-checkbox {
            margin-left: -10px;
          }
        }
      }
    }

    orgbrain-title-bar,
    mat-paginator {
      padding-left: 22px;
      padding-right: 22px;
    }

    .wrapper-of-navigation {
      padding-left: 48px;
      padding-right: 48px;
    }

    .due-diligence-padding {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}

.data-room-dialog {
  .close-button-dataroom-dialog {
    padding-top: 15px;
    padding-right: 15px;
  }

  .mat-mdc-dialog-surface {
    padding: 0;
    min-height: 150px !important;
  }

  @media (min-width: 701px) {
    display: flex;

    .mat-mdc-table {
      tr {
        th:first-of-type {
          padding-left: 32px !important;

          mat-checkbox {
            margin-left: -10px;
          }
        }

        td:first-of-type {
          padding-left: 32px !important;

          mat-checkbox {
            margin-left: -10px;
          }
        }
      }
    }

    orgbrain-title-bar,
    mat-paginator {
      padding-left: 8px;
      padding-right: 8px;
    }

    .wrapper-of-navigation {
      padding-left: 32px;
      padding-right: 32px;
    }

    .due-diligence-padding {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.image-preview-wrapper-original {
  max-height: 100vh !important;
  max-width: 100vw !important;

  .mat-mdc-dialog-surface[class] {
    overflow: hidden;
    padding: 0;
    border-radius: 0;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.suggestion-card.cdk-drag-placeholder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.forcing-no-padding-in-expansion-panel-body-in-mobile {
  .mat-expansion-panel-body {
    @media screen and (max-width: 700px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.error-field-hack-100 {
  .mat-mdc-form-field-error-wrapper {
    width: 100px;
  }
}

$big-text-size: 1.8rem;
$big-icon-size: 2.5rem;

.big-text-orange {
  font-size: $big-text-size;
  font-weight: 500;
  color: #f57c00;
}

.big-icon {
  font-size: $big-icon-size !important;
  width: $big-icon-size !important;
  height: $big-icon-size !important;
}

.wierd-position-of-slide-toggle-lable-kaj-design {
  .mdc-form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    label {
      margin-top: 6px;
    }
  }
}

app-section {
  .teori {
    h2 {
      font-size: 18px;
      font-weight: 500;
      color: #0097a7;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #123340;
    }
  }
}

.border-top-1px-cfd8dc {
  border-top: 1px solid #cfd8dc;
}

.widest-ever-radio-button {
  .mdc-form-field {
    width: 100%;
    .mdc-label {
      width: 100%;
    }
  }
}

.already-contacted-chip {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: white;
  background-color: #48964c;
  height: 28px;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;

  display: flex;
  align-items: center;
}

.mat-radio-group-white {
  .mdc-radio__outer-circle {
    border-color: white !important;
  }
  .mdc-label {
    color: white !important;
  }

  /* Style for the inner circle (selected dot) */
  .mdc-radio--checked .mdc-radio__inner-circle {
    background-color: white !important;
  }

  /* Style for the outer circle (when selected) */
  .mdc-radio--checked .mdc-radio__outer-circle {
    border-color: white !important;
  }

  /* Style for the outer circle on hover */
  .mdc-radio:hover .mdc-radio__outer-circle {
    border-color: rgb(238, 39, 39) !important;
  }

  /* Style for the outer circle on focus */
  .mdc-radio:focus .mdc-radio__outer-circle {
    border-color: lightgray !important;
  }
}
.agendaItemView {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chip-row-fixed-for-dropdown {
  .mdc-evolution-chip__action.mdc-evolution-chip__cell {
    width: 100%;
    overflow: hidden;
    .mdc-evolution-chip__text-label {
      width: 100%;
      display: flex;
    }
  }
}

.mat-drawer-inner-container {
  scrollbar-width: thin;
}
.mat-mdc-dialog-surface {
  scrollbar-width: thin;
}
.mat-mdc-card {
  box-shadow: none;
}

mat-icon {
  font-variation-settings: 'wght' 300;
}
.orgbrain-add-fab.mdc-fab {
  display: none;
}

@media screen and (max-width: 768px) {
  // Helper class that can be added to panelClass in MatDialogConfig.
  // This displays a dialog as a closable page (trick)
  .orgbrain-full-screen-dialog-sm {
    height: 100vh !important;
    max-height: 100vh;
    max-width: 100vw !important;
    width: 100vw !important;

    mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface {
      border-radius: 0;
    }
    .mdc-dialog__surface {
      padding-top: 16px;
    }
  }

  .orgbrain-add-fab.mdc-fab {
    display: block;
    border-radius: 12px !important;
    height: 56px;
    width: 56px;
    position: fixed;
    right: 1.5em;
    bottom: 1.5em;
  }
}

.notification_style {
  .notification-org-header {
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: black;
  }

  .notification-cluster-title {
  }

  .notification-title {
    margin-left: 15px;
    text-align: left;
    font: normal normal bold 14px/20px Roboto;
    letter-spacing: 0px;
    color: #546e7a;
    opacity: 1;
  }

  .notification-body {
    text-align: left;
    margin-left: 15px;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #546e7a;
    opacity: 1;
    max-width: 350px;
    word-break: break-word;
  }

  .notification-date {
    padding-right: 15px;
    text-align: right;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #546e7a;
    opacity: 0.7;
  }

  .notification-cluster-header {
  }
}

.show-validation-errors {
  .ng-dirty {
    mat-form-field.ng-invalid,
    orgbrain-autocomplete-input.ng-invalid {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -7px;
        right: -7px;
        bottom: 2px;
        border: 2px solid #f57c00;
        border-radius: 10px;
      }
    }
  }
}

orgbrain-markdown-display {
  overflow-wrap: break-word;
}
