$button-gap-default: 16px;
$default-mobile-breakpoint: 600px;
$full_screen_width: 960px;

.orgbrain-style {
  &-box {
    p,
    div {
      box-sizing: border-box;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .scroll-container {
    flex-grow: 1;
    overflow-y: auto;
  }

  .tab-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding-top: 20px;
    width: 100%;
    justify-content: left;
  }

  .tab-page {
    display: flex;
    flex-direction: column;
    justify-content: left;
    box-sizing: border-box;
    width: auto;
    min-height: 410px;
    padding: 48px 64px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    @media screen and (max-width: $default-mobile-breakpoint) {
      //   max-width: 90%;

      padding-left: 20px;
      padding-right: 20px;
      margin: 0px;
    }
  }

  .page {
    display: flex;
    flex-direction: column;
    justify-content: left;
    box-sizing: border-box;
    width: auto;
    min-height: 410px;
    padding: 48px 64px;
    background-color: white;
    margin: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    @media screen and (max-width: $default-mobile-breakpoint) {
      //   max-width: 90%;

      padding-left: 20px;
      padding-right: 20px;
      margin: 0px;
    }
  }

  .page-margin {
    margin: 20px;
    @media screen and (max-width: $default-mobile-breakpoint) {
      margin: 0px;
    }
  }

  .short-page {
    display: flex;
    flex-direction: column;
    justify-content: left;
    box-sizing: border-box;
    width: auto;
    padding: 20px 48px;
    background-color: white;
    margin: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    @media screen and (max-width: $default-mobile-breakpoint) {
      //   max-width: 90%;

      padding-left: 20px;
      padding-right: 20px;
      margin: 0px;
    }
  }

  // Use all of the row.
  // If no spacers justify content is right
  .button-row-full {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: $button-gap-default;
  }

  // Mobile single it per row.
  @media screen and (max-width: $default-mobile-breakpoint) {
    .button-row-full {
      display: flex;
      flex-wrap: null;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
      gap: $button-gap-default;
    }
  }

  .button-row-fragment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: $button-gap-default;
  }

  .mat-raised-button .button-spacer {
    flex-grow: 1;
    @media screen and (max-width: $default-mobile-breakpoint) {
      display: none;
    }
  }

  .button-row-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: $button-gap-default;
  }

  .button-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;
    button {
      width: 100%; /* Set maximum width to the container width */
    }
  }

  .button-col-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;
    margin: auto;
    button {
      width: 100%; /* Set maximum width to the container width */
    }
  }

  .button-row-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: $button-gap-default;
  }

  .button-row-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: $button-gap-default;
  }
}

.orgbrain-mat-tab-labels-wrap {
  flex-flow: wrap;
}
