@import 'utilities/variables';
@import 'shared/assets/scss/design';

.main-submenu-container {
  border-radius: 6px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.mat-mdc-card:not(.quizcard) {
  padding-bottom: 0;
  border-radius: 12px;
}

.mat-group-user-settings {
  padding-top: 30px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .phone-wrapper .mat-form-field-infix {
    width: 100%;
    max-width: 320px;
  }

  h1,
  h2,
  input,
  label {
    color: #546e7a;
  }

  h1 {
    font-weight: 400;
  }

  mat-form-field {
    width: 100%;
    max-width: 320px;
  }

  .multi-item-row {
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 959px) {
      flex-direction: column;
    }
    mat-form-field:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  .isError {
    color: red;
  }

  .main-content {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 60%;
    width: 100%;
    min-height: 410px;
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: $default-mobile-breakpoint) {
      max-width: 90%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .smallprint {
    font-size: smaller;
    padding-top: 10px;
  }

  .link-with-arrow {
    display: block !important;
    font-size: 1rem;
    font-weight: 500;
    color: #0097a7;
    text-decoration: none;
    cursor: pointer;
    line-height: 1.6rem;

    &::after {
      content: '\2794';
      padding-left: 8px;
    }
  }

  button.mat-mdc-outlined-button,
  button.mat-mdc-raised-button {
    min-width: 120px;
    border-radius: 1000px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  button.mat-mdc-outlined-button:not(.mat-button-disabled) {
    border-color: #0097a7;
  }
}

ngx-mat-intl-tel-input {
  .country-selector[class] {
    top: 10px;
  }

  .mdc-button__label {
    margin-top: 2px;
    margin-left: -7px;
  }

  .country-selector-code {
    color: rgba(0, 0, 0, 0.87);
  }

  .mdc-button__ripple {
    display: none !important;
  }
}

.login-dialog {
  .isError {
    color: red;
  }

  .center {
    text-align: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-item-content {
    padding: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-item,
  .recoveryInfo {
    margin-bottom: 15px;
  }

  mat-form-field {
    width: 100%;
  }

  button#login,
  button#submitResetEmail {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #546e7a;
    cursor: pointer;
  }

  .logo {
    width: 50%;
    max-width: 230px;
    margin: 10px 0 30px;
  }

  .main-content {
    margin: 0 auto;
    max-width: 70%;
    min-height: 410px;

    @media (max-width: 480px) {
      h1,
      p {
        text-align: center;
      }

      max-width: 95%;
    }
  }

  .mat-mdc-dialog-content {
    min-height: 160px;
  }

  p {
    color: #546e7a;
  }

  .container {
    margin-top: 40px;
    max-width: 400px;
    text-align: center;
  }

  mat-form-field,
  button#register,
  .center {
    width: 100%;
  }

  button#register {
    margin-top: 10px;
  }

  a.blue-link:link {
    font-weight: 500;
    color: #0097a7;
    text-decoration: none;
    cursor: pointer;
  }

  /* visited link */
  a.blue-link:visited {
    color: #aa97a7;
    text-decoration: none;
    cursor: pointer;
  }

  .red {
    color: red;
  }
}

.backdropBackground {
  background-color: #f5f5f5;
  box-shadow: none !important;
}

.backdropBackgroundOrgbrain {
  @media screen and (min-width: 800px) {
    background-image: url('../../assets/logos/orgbrain_logo_background.svg');
    background-position: 10px 10px; /* Center the image */
    // background-size: cover; /* Resize the background image to cover the entire container */
    background-repeat: no-repeat;
    background-color: #eef1f3;
  }

  @media screen and (max-width: 800px) {
    background-color: #eef1f3;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-group-compact {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label-content {
    padding-left: 3px;
  }
}

.centered-responsive-dialog {
  max-height: 100vh;
  width: 100%;
  mat-dialog-container .mat-mdc-dialog-surface {
    border-radius: 0;
  }
}

.full-screen-left-dialog {
  height: 100vh;
  width: 50vw !important;
  mat-dialog-container .mat-mdc-dialog-surface {
    border-radius: 0;
  }
}

.fullscreen-dialog-with-x-button {
  position: relative;
  min-width: 70vw;
  min-height: 10vh;

  video {
    max-height: 99vh;
  }
  @media screen and (max-width: 1030px) {
    min-width: 90vw;
  }
  iframe {
    min-width: 100%;
    min-height: 60vh;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    display: flex;
    align-items: center;
    .mat-mdc-dialog-surface {
      padding: 0;
    }

    * {
      // need this to remove white space in video modal
      display: block;
    }
  }

  .close-video-dialog-button {
    position: absolute;
    top: 35px;
    right: 36px;
    color: white;
    opacity: 0.8;
    transform: scale(3);
    transition: opacity 0.3s ease;
    background: transparent !important;
    border: none;
    outline: none;
    cursor: pointer;
    mat-icon {
      color: #cfd4d6;
    }
    &:hover {
      background: transparent !important;
      opacity: 1;
    }
  }
}

.fullscreen-dialog-full-size {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  min-width: 100% !important;
  min-height: 100% !important;

  .mat-mdc-dialog-surface {
    position: relative;
    background: #cfd8dc !important;
    border-radius: 0 !important;
    padding: 0px;
    overflow-x: hidden;
  }
  .fullscreen-dialog-content-wrapper {
    max-width: 1000px;
    margin: 50px auto 50px;

    font-family: Roboto, 'Helvetica Neue', sans-serif;
    @media screen and (max-width: 800px) {
      margin: 0;
      padding-bottom: 100px;
    }

    @media screen and (min-width: 819px) {
      .fullscreen-dialog-inner-wrapper {
        border-radius: 6px !important;
        .fullscreen-dialog-header {
          border-top-left-radius: 6px !important;
          border-top-right-radius: 6px !important;
        }
      }
    }
    .close-dialog-button {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #546e7a;
      opacity: 1;
      transition: opacity 0.3s ease;
      background: white !important;

      outline: none;
      cursor: pointer;

      height: 24px;
      width: 24px;
      font-size: 16px;
      border-radius: 50%;
      border: 1px solid white;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: white !important;
        opacity: 0.8;
      }
      mat-icon {
        margin-left: -0.5px;
      }
    }

    .fullscreen-dialog-inner-wrapper {
      background: white;
    }
    .fullscreen-dialog-header {
      background: #546e7a;
      position: relative;
      h2 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #ffffff;
        padding-top: 10px;
      }
      h3 {
        font-size: 1.1rem;
        font-weight: 400;
        color: #ffffff;
      }
      padding: 10px 20px;
    }
    .fullscreen-dialog-content {
      padding: 10px 64px;
      color: #000000bf;
      @media screen and (max-width: 700px) {
        padding: 10px 16px;
      }
      h2 {
        font-size: 1.2rem;
        font-weight: 400;
        color: #546e7a;
      }
    }
  }
}

.button-arrow {
  border: solid $black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.3s ease;
  &.arrow-right {
    transform: rotate(-45deg);
  }

  &.arrow-left {
    transform: rotate(135deg);
  }

  &.arrow-up {
    transform: rotate(-135deg);
  }

  &.arrow-down {
    transform: rotate(45deg);
  }
}

.cdk-drag-preview.drag-and-rotate > * {
  transform: rotate(5deg) !important;
  cursor: pointer !important;
}

.hide-checkbox {
  input[type='checkbox'],
  mat-pseudo-checkbox {
    display: none;
  }
}

mat-option {
  mat-pseudo-checkbox[class] {
    display: none;
  }
}

.transparent-expansion-panel {
  background: transparent !important;
  box-shadow: none !important;
  .mat-expansion-panel-header {
    padding: 0 15px;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.hide-nav-buttons {
  .meeting-nav-buttons-wrapper {
    display: none !important;
  }
}

.disabled-completely {
  opacity: 0.8;
  pointer-events: none;
}

.mat-mdc-tabs-orgbrain {
  .mat-mdc-tab-header {
    border: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    opacity: 1 !important;
    font-weight: normal;
    font-size: 13px;
    color: #546e7a;

    @media screen and (max-width: 800px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    @media screen and (max-width: 640px) {
      font-size: 12px;
    }

    @media screen and (max-width: 380px) {
      font-size: 11px;
    }

    @media screen and (min-width: 600px) and (max-width: 699px) {
      min-width: 82px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-labels {
    padding-bottom: 8px;

    @media screen and (max-width: 767px) {
      justify-content: space-around;
    }
  }
  .mat-ink-bar {
    height: 4px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-wrapper {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.mat-mdc-tabs-orgbrain-no-box-shadow {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-labels {
    @media screen and (min-width: 786px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-wrapper {
    box-shadow: none !important;
  }
}

.relative-noscroll-panel {
  .mat-mdc-dialog-container[aria-modal='true'] {
    position: relative;
    overflow: hidden;
  }
}

.ftn-documentviewer-wrapper {
  max-height: 100vh !important;
  max-width: 100vw !important;
  .mat-mdc-dialog-container[aria-modal='true'] {
    overflow: hidden;
    padding: 0;
  }
  iframe {
    margin-left: -2px;
    margin-top: -2px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog-content-no-padding {
  @media screen and (max-width: 767px) {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 0.8rem;
    }
  }
}

.meeting-attendees-panel {
  .mat-expansion-panel-body {
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    mat-cell:first-of-type {
      padding-left: 8px;
    }
    mat-cell:last-of-type {
      padding-right: 8px;
    }
  }
  .mat-mdc-tabs-orgbrain {
    @media screen and (min-width: 768px) {
      padding: 0 24px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-group-white {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-color: white;
  }
}

.meeting-notes-panel {
  @media screen and (max-width: 767px) {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card.note-item {
    border-radius: 0;
    &.little-shadow {
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.07), 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
        0px 1px 8px 0px rgba(0, 0, 0, 0.07);
    }
  }
}

.no-expansion-body-padding-sides {
  .mat-expansion-panel-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.no-expansion-header-styles {
  .mat-expansion-panel-header {
    background: none !important;
    padding-left: 0;
  }
}

.no-expansion-body-padding-bottom {
  .mat-expansion-panel-body {
    padding-bottom: 0px !important;
  }
}

.content-container-shareholders {
  h2 {
    color: $orgbrain-blue;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card h2 {
    color: $orgbrain-gray;
  }

  margin: 20px;
}

.rightAlignCellShareholders {
  justify-content: flex-end;
  flex: 0.5;
  text-align: right;
}

.content-container-shareholders,
.chart-container-shareholders {
  flex-grow: 1;
}

.chart-container-shareholders,
.chart-nolabels-container-shareholders {
  // margin: auto 0;
  padding: 30px;
  flex-basis: 600px;
  max-width: 600px;
  text-align: center;

  // max-height:
  h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: $orgbrain-gray;
    text-align: left;
  }
}

.chart-nolabels-container-shareholders {
  flex-basis: 450px;
  max-width: 500px;
  display: none;
}

.flex-container-shareholders {
  display: flex;
  flex-wrap: wrap;

  div button.manage {
    float: right;
  }

  .mat-icon,
  button {
    flex-basis: auto;
  }
}

.flex-container-shareholders > *,
thousand-separator-input > * {
  flex-grow: 1;
  margin-right: 16px;
}
.mat-mdc-list-item.flex-container-shareholders {
  .mat-mdc-form-field {
    margin-right: 16px;
  }
}

.flex-container-shareholders > .fill-form {
  flex-grow: 1;
}

/* styles.css or app.component.css */
.wide-tooltip {
  .mdc-tooltip__surface {
    max-width: 650px;
    padding: 5px;
  }
}
